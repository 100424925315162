<template>
  <b-autocomplete
      class="tete"
      :placeholder="placeholder"
      v-model="inputVal"
      :data="data"
      :field="field"
      :open-on-focus="openOnFocus"
      expanded
      clearable
      @select="v => $emit('select', v)">
      <template #empty>No results found</template>
  </b-autocomplete>
</template>

<script>
import { BAutocomplete } from "buefy/src/components/autocomplete";

export default {
  name: "Autocomplete",
  components: { BAutocomplete },
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    data: {
      type: Array,
			default: () => ([])
    },
    openOnFocus: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: "nama"
    }
  },
  data() {
    return {
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    filteredDataObj() {
        return this.data.filter(option => {
            return (
                option.nama
                    .toString()
                    .toLowerCase()
                    .indexOf(this.inputVal.toLowerCase()) >= 0
            )
        })
    }
  }
}
</script>
<style lang="scss">
  @import "~bulma/sass/utilities/_all";
  @import "~bulma/sass/components/dropdown";
  @import "~buefy/src/scss/components/dropdown";
  @import "~buefy/src/scss/components/autocomplete";
</style>
<style scoped>
  .tete >>> input,
  .tete >>> select {
    width: 100%;
    padding: 16px 13px 16px 20px;
    border-radius: 4px;
    border: 1px solid hsl(233, 30%, 21%);
    background-color: hsl(233, 31%, 17%);
    font-weight: 700;
    color: white;
  }
  @media screen and (max-width: 1024px) {
    .tete >>> input,
    .tete >>> select {
      padding: 10px 8px 10px 8px;
    }
  }
</style>
